@import "./responsive.scss";

.about-container {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  // &.show-slogan:after {
  //   position: fixed;
  //   display: block;
  //   content: ' ';
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100vh;
  //   background: linear-gradient(black, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), black);
  // }

  section {
    margin: auto;
  }

  .inner-width {
    width: 75%;
    max-width: 1012px;
    margin: 0 auto;

    @media (min-width: 577px) and (max-width: 1000px) {
      padding: 0;
    }

    @media screen and (max-width: 576px) {
      width: 100%;
      padding: 0 5%;
    }
  }

  section.aflow {
    position: relative;
    overflow: hidden;

    .main-title {
      font-size: 6rem;
      margin-bottom: 1em;
      position: relative;
      margin-top: 50vh;
      padding-left: $col-width * 0.6;
      padding-right: $col-width * 0.6;
      animation-duration: 800ms;

      @media screen and (max-width: 576px) {  
        font-size: 4.3rem;
        line-height: 1.35;
        letter-spacing: 0;
        padding: 0 20px;
        word-break: break-word;
        white-space: pre-line;
      }

     > p {
        font-size: 6rem;
        line-height: 1.25;
        display: block;
        text-align: left;
        color: white;
        animation-fill-mode: both;
        word-break: keep-all;
        white-space: nowrap;

        @media screen and (max-width: 800px) {  
          font-size: 4.3rem;
          line-height: 1.35;
          letter-spacing: 0;
          padding: 0;
          word-break: keep-all;
          white-space: pre;
        }
     }
    }

    p {
      position: relative;
      font-size: 1.7rem;
      will-change: top, opacity;

      &.message {
        animation-fill-mode: both;
        opacity: 0;
        padding-left: $col-width * 0.6;
        padding-right: $col-width * 0.6;
        word-break: keep-all;
        color: #b5b5b5;

        @media screen and (max-width: 578px) {
          padding:0 20px;
        }

        &.ko {
          animation-duration: 1100ms;
          line-height: 2.43;

          @media screen and (max-width: 578px) {
            line-height: 1.8;
          }
        }

        &.en {
          //animation-delay: 100ms;
          animation-duration: 1200ms;
          padding-bottom: 12.5%;
          line-height: 2.3;
          letter-spacing: -0.015rem;

          @media screen and (max-width: 578px) {
            line-height: 2;
          }
        }
      }
    }
  }

  .white-bg {
    position: relative;
    background-color: #ffffff;
    font-family: "Noto regular", serif;
    overflow: visible;


    p.title {
      font-family: "Noto bold", serif;
      padding-top: 13%;
      letter-spacing: 0.12rem;
      line-height: 1;
      font-size: 1.4rem;

      @media screen and (max-width: 576px) {
        margin-top: 14.4%;
      }
    }

    p.subtitle {
      font-weight: bold;
      line-height: 1.6;
      font-size: 2.2rem;
      margin-bottom: 2.2rem;
      letter-spacing: 0;

      @media screen and (max-width: 578px) {
        font-size: 20px;
        margin-bottom: 25px;
        line-height: 1;
      }
    }

    .business-wrap {
      height: 420px;

      > div {
        margin-top: 8.8%;
        display: flex;
        flex-flow: row;

        @media screen and (max-width: 1000px) {
          flex-flow: column;
          height: auto;
  
          > div {
            margin-top: 14.4%;
  
            &:first-of-type {
              margin-top: 0;
            }
          }
        }

        >div{ 
          width: 100%;
          flex-grow: 1;
          font-size: 1.4rem;
          line-height: 2.28;
          letter-spacing: 0.03rem;
          z-index: 2;

          @media screen and (max-width: 578px) {
            font-size: 12.5px;
            line-height: 2;
            letter-spacing: 0;
          }
        }
        
      }
    }

    .business-wrap2 {
      position: relative;
      margin-top: 0;

      .area-title-list {
        position: relative;
        flex-grow: 1;
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.8rem;
        line-height: 1.6;

        @media screen and (max-width: 578px) {
          line-height: 1.222;
        }

        > li {
          position: relative;
          margin-top: 60px;

          &:first-of-type {
            margin-top: 0;
          }
        }

        @media screen and (min-width: 1000px) {
          position: relative;
          width: 50%;
          height: 100%;

          > li {
            margin-top: 0;
            position: absolute;

            &:first-of-type {
              right: 33.33%;
              bottom: 0;
              transform: translateY(100%);
            }

            &:nth-of-type(2) {
              top: 0;
              right: 0;
              transform: translateX(50%);
            }

            &:last-of-type {
              right: 66.6%;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }

      .area-list {
        position: absolute;
        margin: 0;
        width: 50%;
        left: 50%;
        top: 60%;
        padding: 0;
        list-style: none;
        font-size: 1.4rem;
        line-height: 2.14;
        display: flex;
        flex-flow: row;
        transform: translate(3rem, -0.5rem);
        

        > li {
          white-space: nowrap;

          &:first-of-type {
            width: 130px
          }

          &:nth-of-type(2) {
            width: 170px;
          }

          &::nth-of-type(3) {
            width: 150px;
          }
        }


        @media screen and (max-width: 1000px) {
          flex-flow: column;
        }
      }
    }

    .background-shape2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }
}

.about-dark-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  overflow: hidden;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: top linear 150ms;
  }
}

.client {
  margin-top: 200px !important;

  @media screen and (max-width: 576px) {
    width: auto;
    margin: 25px;
  }

  .client-list {
    list-style: none;
    margin: 2% auto 0;
    padding: 0;
    position: relative;
    overflow: hidden;

    > li {
      margin-top: 2%;
      float: left;
      width: 16.66%;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        width: 100%;
      }

      @media screen and (max-width: 576px) {
        width: 33%;
      }
    }
  }
}

.prototype {

  position:absolute !important;
  top:0px;
  left:50%;
  transform: translateX(-50%);
  opacity:0.2;
  width: 1600px;
  z-index: 99999;
  pointer-events: none;
}

#w0-proto2 {
  top:-2695px;
}
