@media screen and (max-width: 576px) {
  .page {
    .scroll-content {
      overflow-x: hidden;
    }

    .w-overlay-line {
      position: fixed;
      top: 0;
      left: -1px;
      width: 100%;
      height: 100%;
      z-index: 1000;
      opacity: 1;

      &.animate {
        stroke-dasharray: 3000;
        stroke-dashoffset: 3000;
        animation: dash 3s ease-out forwards;
      }
    }

    #work-swiper {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: url(/image/w_bg_m.jpg) center top no-repeat;
      background-size: cover;

      .swiper-slide {
        position: relative;
        // background-color: red;
        width: 100%;
        height: 100%;

        .img-wrap {
          position: absolute;
          width: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;

          >img {
            position: relative;
            width: 100%;
          }
        }
      }
    }

    #w-overlay-top1 {
      position: fixed;
      top: 0;
      left: 0;
      height: 185px;
      width: 100%;
      z-index: 9;
      background-color: white;
    }

    #w-overlay-top2 {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      margin-top: 184px;
      height: 50vw;
      clip-path: polygon(0 0, 0 100%, 100% 0);
      background-color: white;
      z-index: 8;
    }

    #w-overlay-bot {
      position: fixed;
      bottom: 0;
      left: -26vw;
      width: 100vw;
      height: 200vw;
      background-color: rgba(255, 255, 255, 0.4);
      clip-path: polygon(0 0, 0 100%, 100% 100%);
      z-index: 8;

      &.hide {
        display: none;
      }
    }

    .pagination {
      position: fixed;
      margin-top: 50vw;
      top: 178px;
      left: 0;
      width: 32px;
      height: 64px;
      overflow: hidden;
      z-index: 9;
      transform: translateY(-50%);

      >svg {
        position: absolute;
        top: 22px;
        left: 0;
        margin-left: -4px;
        width: 40px;
      }

      >span {
        position: absolute;
        font-size: 13px;
        line-height: 1;
        display: block;
        text-align: center;

        &:first-of-type {
          top: 0;
          left: 0;
          width: 100%;
          font-weight: bold;
        }

        &:last-of-type {
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }
    }
  }

  .scroll-down {
    background: url(/image/scroll_down.png) center left no-repeat;
    background-size: 9.5px 6px;
    padding-left: 20px;
    position: fixed;
    left: 3%;
    bottom: 37.5px;
    font-size: 12px;
    line-height: 1;
    z-index: 20;
    transition: opacity 0.3s ease-in-out;
  }

  .not-updated {
    position: absolute;
    bottom: 35px;
    left: 3%;
    font-size: 12px;
    color: black;
    z-index: 20;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }
}

.desktop-column {
  margin-left: 3%;
  max-width: 94%;
  padding: 5% 0 0;

  >img {
    width: 100%;
  }
}

#title-swiper {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 70vw;
  color: black;
  font-size: 11.2vw;
  text-align: left;
  z-index: 10;
  white-space: pre-line;
  letter-spacing: 0;
  line-height: 12vw;

  .swiper-slide {
    padding: 20px 0 0 32px;
    white-space: pre;

    h3 {
      // width: 100%;
      font-size: 3.5rem;
      line-height: 1.14;
      margin: 0;
      margin-left: -2px;
      opacity: 0;
    }

    p {
      margin-left: 0;
      font-size: 13px;
      opacity: 0;
    }

    &.swiper-slide-active {

      h3,
      p {
        opacity: 1;
        transition: opacity .6s ease-in-out;
      }
    }
  }



  >div {
    position: absolute;
  }
}