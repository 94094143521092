@import "./responsive.scss";

@media screen and (min-width: 578px) {

  .loaded .footer {
    opacity: 1;
  }
  
  .footer {
    position: relative;
    color: rgba(227, 227, 227, 0.5);
    overflow: hidden;
    opacity: 0;

    > svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .footer-container {
      position: absolute;
      bottom: 200px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 75%;
      max-width: 1012px;
      height: 300px;
      text-align: right;
    }

    p {
      line-height: 1;
    }

    a {
      color: rgba(227, 227, 227, 0.5);
      text-decoration: none;
    }

    > * {
      position: relative;
      font-family: Alogo;
    }

    .background-shape {
      position: absolute !important;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    > .col-6 {
      color: #e3e3e3;
      top: 160px;
      text-align: right;
    }

    .label {
      color: rgba(128, 128, 128, 0.5);
      // margin-bottom: 12px;
      line-height: 2.14;
    }

    .logo {
      width: 5rem;
    }

    .alogo {

      &.corp {
        margin-top:2rem;
        margin-bottom: 4.56vw;
        font-size: 1.4rem;
        letter-spacing: 0.1em;
        line-height: 1;
        color: #808080;
      }
    }

    .info {
      position: absolute;
      color: rgba(227, 227, 227, 0.5);
      right: 0;
      bottom: 0;
      font-size: 1.4rem;
      letter-spacing: 0.05em;

      a {
        color: rgba(227, 227, 227, 0.5);
      }

      p {
        line-height: 2.14;
      }

      .phone,
      .contact {
        position: absolute;
        right: $col-width*2;
      }

      .phone {
        top: 0;
      }
      .contact {
         bottom: 0;

        a {
          text-decoration: underline;
        }
      }
      .address {
        width: $col-width*2;
        margin-left: auto;
      }

      .instagram {
        position: absolute;
        width: $col-width*2;
        right: $col-width*4;
        bottom: 0;

        a {
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (max-width: 577px) {

  .footer {
    margin-top: 0;
    position: relative;
    width: 100%;
    padding: 59px 20px 150px;
    background-color: black;
    font-size: 1.4rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: right;
    color: rgba(227, 227, 227, 0.5);

    &:after {
      position: absolute;
      display: block;
      content: ' ';
      top: -1px;
      left: 0;
      width: 100%;
      height: 50vw;
      background-color: white;
      clip-path: polygon(0 0, 0 100%, 100% 0);
    }

    .footer-container {
      margin-top: 100px;
      position: relative;
      width: 100%;
    }

    p {
      line-height: 1;
    }

    a {
      color: rgba(227, 227, 227, 0.5);
      text-decoration: none;
    }

    .background-shape {
      position: absolute !important;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    > .col-6 {
      color: #e3e3e3;
      top: 160px;
      text-align: right;
    }

    .label {
      color: rgba(128, 128, 128, 0.5);
      // margin-bottom: 12px;
      line-height: 2;
    }

    .logo {
      width: 5rem;
      margin-bottom: 1rem;
    }


    .info {
      position: relative;
      color: rgba(227, 227, 227, 0.5);
      display: flex;
      flex-flow: column-reverse;
      margin-top: 35px;
      font-size: 1.4rem;

      a {
        color: rgba(227, 227, 227, 0.5);
      }

      p {
        line-height: 2;
      }

      .address {
        margin-top: 3rem;
      }
      .phone {
        margin-bottom: 35px;
      }

      .contact {
        margin-top: 3rem;

        a {
          text-decoration: underline;
        }
      }

      .instagram {
        a {
          text-decoration: underline;
        }
      }
    }
  }

  .swiper-slide #footer::after {
    display: none;
  }
}
