html, body {
  height: 100%;
  overflow: hidden; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }


@font-face {
    font-family: 'Alogo';
    font-style: normal;
    font-weight: normal;
    src: url(data:font/woff;base64,d09GRk9UVE8AAASMAAsAAAAABkwAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABDRkYgAAADOAAAARAAAAEWqnNhL0ZGVE0AAARoAAAAHAAAAByJUbmMR0RFRgAABEgAAAAgAAAAJgAnAChPUy8yAAABaAAAAFIAAABgWUJWT2NtYXAAAALoAAAANwAAAUIADQLoaGVhZAAAAQgAAAA1AAAANhLKTaNoaGVhAAABQAAAAB4AAAAkBikBjmhtdHgAAASEAAAACAAAAAgFKABGbWF4cAAAAWAAAAAGAAAABgACUABuYW1lAAABvAAAASsAAAIB011C+HBvc3QAAAMgAAAAFgAAACD/hgAzeJxjYGRgYADimGu1d+P5bb4ycDO/AIow3Oj+7gKme4QOMYgwMDA1MO0AcjkYmECiAF0iC1QAAAB4nGNgZGBguv/vO8MJpikMQMDUwMDIgAqYAILCBLsAAAAAUAAAAgAAeJxjYGGawjiBgZWBgamLKYKBgcEbQjPGMRgxGgBFuVlZmECABSjHzoAEHF2cXBkOMDgyODLd//ed4QSLECMbUJgRJMf4m2kHgwIQMgIADsoMNgAAeJx1jzFuwjAYhZ+BBLWqUI/wqxNIJHIiMcBUSpWtCwM7CpETCcU0BKncoGNP0uP0Lt36YizapZbs/7P9/P5nACN8QuEyBE+eFUK8eu5hiHfPfTzgy/MAoRp6DnCvtOcQgXqhUg1uuHt0rzpWuEPuuce+b577eMaH5wE1354DiBp5DnGrlljB4oAzGlQwKNEy8ZieE9YUGgnmmJJz3llqdlxrp7SOsLKHc1OZspVxPpFUJ/Op5KWtdrY2pbVULPl8T7HhxFL21rCuUfDgxIste2NdmNN+S8ica+tqQ0XhcsRMIlhw/jW7nCSYIaImuuZFZus2s40pJI21LOTSlJDMojTqMv6TasN+DY78Z5dC6Nc5xq52CbApmmNla9E6ibXW0vn8ujiTH5w5Si4AeJxjYGBgZoBgGQZGBhCwAfIYwXwWBgUgzQKEIL7j//8Q8v8BqEoGRjYGGJN8QLEBAwsAfbsG9AB4nGNgZgCD/80MRkCKkQENAAAoVQG5AAB4nGNkYGFhYGRkZHPMyU/PZ2BkYmBk0P3Bz/BDmvGHDNMPWeYfEiw9PIzzu3+K/PRhlWGo4pdhYBCQYagVlGEwl2G4L8TADNKiyKDOoOucX1BZlJmeUaKgkaypYGRgaKmjkJyRn5mSn5eekZ+f56gAsgNCMgCBEgMTI6NSbPfeH1J7Gffu/ZGwl3mv2I8jP+r+HGHb+8dH9IfUj4Q/Uux8C8q+p976/vAa4/yfDMzzxb5P+enzna370+8t39Wvduv/5g78zv37gX634vddv/3013/5LnDS4nvl78I/r1j5fmqIdIvWzPrRP+u7zKxFQMj2O2sWO7IAB1CAU46L9T8PZzcPVzcP9y2ux9wACuZsXHicY2BkYGDgAWIxBjkGJgZGKGQBijABMSMEAwAIXQBQAAAAAQAAAADYOa+FAAAAANiL90QAAAAA2IwSwgKUADIClAAU) format('woff');
    unicode-range: U+0041;
}
    
/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'Acumin Pro';
font-style: normal;
font-weight: normal;
src: local('Acumin Pro'), url(/fonts/Acumin-RPro.woff?e6b4f4d98674c47455f0a1f9e18aa028) format('woff');
}


@font-face {
font-family: 'Acumin Pro italic';
font-style: normal;
font-weight: normal;
src: local('Acumin Pro Italic'), url(/fonts/Acumin-ItPro.woff?acee2e5602c8850641012ff2fbb6986d) format('woff');
}


@font-face {
font-family: 'Acumin Pro bold';
font-style: normal;
font-weight: normal;
src: local('Acumin Pro Bold'), url(/fonts/Acumin-BdPro.woff?31d32c66e73b9e53840127c91eeef391) format('woff');
}


@font-face {
font-family: 'Acumin Pro bold italic';
font-style: normal;
font-weight: normal;
src: local('Acumin Pro Bold Italic'), url(/fonts/Acumin-BdItPro.woff?53bea630cfe5c4fd7f124087b75de7ab) format('woff');
}

@font-face {
  font-family: 'Acumin Pro Semibold';
  font-style: normal;
  font-weight: normal;
  src: url(/fonts/Acumin-Semibold.woff?0dc41e58a2b62b4bc74df43c88c61ca4) format('woff');
  }
  

@font-face {
    font-family: 'Noto regular';
    font-style: normal;
    font-weight: normal;
    src: url(/fonts/NotoSans-Regular.woff?53e1309e4fc9372afba4fd8f872a7aa5) format('woff');
}

@font-face {
    font-family: 'Noto medium';
    font-style: normal;
    font-weight: normal;
    src: url(/fonts/NotoSans-Medium.woff?024dc32a28bc99fe1a3e5f7628989a65) format('woff');
}

@font-face {
    font-family: 'Noto thin';
    font-style: normal;
    font-weight: normal;
    src: url(/fonts/NotoSans-Thin.woff?7adb7d46ce6f8afd982e430b90c52619) format('woff');
}

@font-face {
    font-family: 'Noto light';
    font-style: normal;
    font-weight: normal;
    src: url(/fonts/NotoSans-Light.woff?d04dd8702c5cc108a75e99f43ba7fcbb) format('woff');
}

@font-face {
    font-family: 'Noto demilight';
    font-style: normal;
    font-weight: normal;
    src: url(/fonts/NotoSans-DemiLight.woff?0cebeac099c6b89e86bdc5a41622181a) format('woff');
}

@font-face {
    font-family: 'Noto bold';
    font-style: normal;
    font-weight: normal;
    src: url(/fonts/NotoSans-Bold.woff?a123e4ed0d51aa4487d91274a452a981) format('woff');
}

@font-face {
    font-family: 'Noto black';
    font-style: normal;
    font-weight: normal;
    src: url(/fonts/NotoSans-Black.woff?0961c58cc3d39491087853f7cfb259a2) format('woff');
}
/*
Font List:
    * Acumin Pro
    * Acumin Pro italic
    * Acumin Pro bold
    * Acumin Pro bold italic

    * Alogo

    * Noto regular
    * Noto medium
    * Noto thin
    * Noto light
    * Noto demilight
    * Noto bold
    * Noto black
 */
* {
  font-family: "Acumin Pro", "Noto demilight", serif; }

.alogo {
  font-family: "Alogo", "Acumin Pro", "Noto demilight", serif !important; }

.font-semibold {
  font-family: 'Acumin Pro semibold', 'Noto regular', sans-serif !important;
  font-weight: bold !important; }

.font-bold {
  font-family: 'Acumin Pro Bold'; }

.appbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  letter-spacing: 0.5px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: color 0.5s ease, top cubic-bezier(0.25, 0.46, 0.45, 0.94) 350ms;
  transition: color 0.5s ease, top cubic-bezier(0.25, 0.46, 0.45, 0.94) 350ms; }
  .appbar.bg-white {
    background-color: white; }
  .appbar * {
    font-family: "Alogo", "Acumin Pro", "Noto demilight", serif; }
  .appbar.hide {
    top: -100px; }
  .appbar .logo {
    display: inline-block;
    width: 24px;
    height: 24px; }
  .appbar.white .logo {
    fill: white; }
  .appbar.white .menu-mobile {
    fill: white; }
  .appbar * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .appbar .menu-left,
  .appbar .menu-right {
    display: flex;
    position: absolute;
    top: 0;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    height: 100%;
    outline: none; }
  .appbar .menu-left {
    left: 3%;
    text-align: left;
    opacity: 0.7; }
    .appbar .menu-left #appbar-swiper {
      width: 100px;
      height: 30px;
      overflow: hidden; }
      .appbar .menu-left #appbar-swiper .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap; }
  .appbar .menu-right {
    right: 3%;
    text-align: right; }
    .appbar .menu-right > .link {
      display: inline-block;
      margin-left: 30px;
      width: 60px;
      text-align: center;
      height: 30px;
      line-height: 30px; }
  .appbar.white * {
    color: #ffffff; }
  .appbar.black * {
    color: #000000; }
  .appbar a {
    text-decoration: none;
    font-family: 'Alogo', 'Acumin Pro'; }
    @media screen and (min-width: 578px) {
      .appbar a.active, .appbar a:hover {
        font-family: 'Alogo', 'Acumin Pro Semibold';
        font-weight: bold; } }

@-webkit-keyframes top-nav-show {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes top-nav-hide {
  from {
    -webkit-transform: none;
    transform: none; }
  to {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.menu-mobile {
  display: none; }

.mobile-menu-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: -1;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }
  .mobile-menu-page.hide {
    left: 100%;
    opacity: 0; }
    .mobile-menu-page.hide > .menus {
      opacity: 0; }
  .mobile-menu-page.show {
    left: 0;
    opacity: 1;
    z-index: 2000; }
    .mobile-menu-page.show > .menus {
      opacity: 1; }
  .mobile-menu-page > .close-btn {
    position: absolute;
    margin: 0;
    padding: 15px;
    top: 0;
    right: 0;
    width: 40px;
    line-height: 40px;
    font-size: 4.44vw;
    text-align: right;
    color: white;
    border: none;
    outline: none;
    background: transparent; }
  .mobile-menu-page > .menus {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    text-align: center;
    color: white;
    font-size: 9.77vw;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-transition-delay: .3s;
            transition-delay: .3s; }
    .mobile-menu-page > .menus * {
      color: white;
      line-height: 1; }
    .mobile-menu-page > .menus > img {
      display: block;
      margin: 30px auto;
      width: 28.5px; }
    .mobile-menu-page > .menus > p {
      line-height: 85px;
      display: block; }
    .mobile-menu-page > .menus > a {
      color: white;
      text-decoration: none;
      font-size: 9.77vw;
      line-height: 1;
      color: white;
      font-weight: normal; }
      .mobile-menu-page > .menus > a:hover, .mobile-menu-page > .menus > a:active {
        font-weight: normal; }
      .mobile-menu-page > .menus > a:last-of-type _::-webkit-full-page-media, .mobile-menu-page > .menus > a:last-of-type _:future, .mobile-menu-page > .menus > a:last-of-type :root .safari_only {
        -webkit-transform: translateX(-10%); }

@media screen and (max-width: 576px) {
  .appbar {
    height: 70px; }
    .appbar.black {
      background-color: white; }
    .appbar.white {
      background-color: black; }
    .appbar .menu-left {
      left: 20px; }
    .appbar .menu-right {
      right: 20px;
      display: none; }
    .appbar .menu-mobile {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      right: 15px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .appbar .menu-mobile > img {
        width: 100%; } }

@media screen and (max-width: 576px) and (max-width: 576px) {
  .appbar .logo {
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out; } }

.col-1 {
  width: 150px;
  margin: auto; }

.col-2 {
  width: 300px;
  margin: auto; }

.col-3 {
  width: 450px;
  margin: auto; }

.col-4 {
  width: 600px;
  margin: auto; }

.col-5 {
  width: 750px;
  margin: auto; }

.col-6 {
  width: 900px;
  margin: auto; }

@media screen and (min-width: 578px) {
  .loaded .footer {
    opacity: 1; }
  .footer {
    position: relative;
    color: rgba(227, 227, 227, 0.5);
    overflow: hidden;
    opacity: 0; }
    .footer > svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .footer .footer-container {
      position: absolute;
      bottom: 200px;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
      width: 75%;
      max-width: 1012px;
      height: 300px;
      text-align: right; }
    .footer p {
      line-height: 1; }
    .footer a {
      color: rgba(227, 227, 227, 0.5);
      text-decoration: none; }
    .footer > * {
      position: relative;
      font-family: Alogo; }
    .footer .background-shape {
      position: absolute !important;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
    .footer > .col-6 {
      color: #e3e3e3;
      top: 160px;
      text-align: right; }
    .footer .label {
      color: rgba(128, 128, 128, 0.5);
      line-height: 2.14; }
    .footer .logo {
      width: 5rem; }
    .footer .alogo.corp {
      margin-top: 2rem;
      margin-bottom: 4.56vw;
      font-size: 1.4rem;
      letter-spacing: 0.1em;
      line-height: 1;
      color: #808080; }
    .footer .info {
      position: absolute;
      color: rgba(227, 227, 227, 0.5);
      right: 0;
      bottom: 0;
      font-size: 1.4rem;
      letter-spacing: 0.05em; }
      .footer .info a {
        color: rgba(227, 227, 227, 0.5); }
      .footer .info p {
        line-height: 2.14; }
      .footer .info .phone,
      .footer .info .contact {
        position: absolute;
        right: 300px; }
      .footer .info .phone {
        top: 0; }
      .footer .info .contact {
        bottom: 0; }
        .footer .info .contact a {
          text-decoration: underline; }
      .footer .info .address {
        width: 300px;
        margin-left: auto; }
      .footer .info .instagram {
        position: absolute;
        width: 300px;
        right: 600px;
        bottom: 0; }
        .footer .info .instagram a {
          text-decoration: underline; } }

@media screen and (max-width: 577px) {
  .footer {
    margin-top: 0;
    position: relative;
    width: 100%;
    padding: 59px 20px 150px;
    background-color: black;
    font-size: 1.4rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: right;
    color: rgba(227, 227, 227, 0.5); }
    .footer:after {
      position: absolute;
      display: block;
      content: ' ';
      top: -1px;
      left: 0;
      width: 100%;
      height: 50vw;
      background-color: white;
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 0);
              clip-path: polygon(0 0, 0 100%, 100% 0); }
    .footer .footer-container {
      margin-top: 100px;
      position: relative;
      width: 100%; }
    .footer p {
      line-height: 1; }
    .footer a {
      color: rgba(227, 227, 227, 0.5);
      text-decoration: none; }
    .footer .background-shape {
      position: absolute !important;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
    .footer > .col-6 {
      color: #e3e3e3;
      top: 160px;
      text-align: right; }
    .footer .label {
      color: rgba(128, 128, 128, 0.5);
      line-height: 2; }
    .footer .logo {
      width: 5rem;
      margin-bottom: 1rem; }
    .footer .info {
      position: relative;
      color: rgba(227, 227, 227, 0.5);
      display: flex;
      flex-flow: column-reverse;
      margin-top: 35px;
      font-size: 1.4rem; }
      .footer .info a {
        color: rgba(227, 227, 227, 0.5); }
      .footer .info p {
        line-height: 2; }
      .footer .info .address {
        margin-top: 3rem; }
      .footer .info .phone {
        margin-bottom: 35px; }
      .footer .info .contact {
        margin-top: 3rem; }
        .footer .info .contact a {
          text-decoration: underline; }
      .footer .info .instagram a {
        text-decoration: underline; }
  .swiper-slide #footer::after {
    display: none; } }

.col-1 {
  width: 150px;
  margin: auto; }

.col-2 {
  width: 300px;
  margin: auto; }

.col-3 {
  width: 450px;
  margin: auto; }

.col-4 {
  width: 600px;
  margin: auto; }

.col-5 {
  width: 750px;
  margin: auto; }

.col-6 {
  width: 900px;
  margin: auto; }

.about-container {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .about-container section {
    margin: auto; }
  .about-container .inner-width {
    width: 75%;
    max-width: 1012px;
    margin: 0 auto; }
    @media (min-width: 577px) and (max-width: 1000px) {
      .about-container .inner-width {
        padding: 0; } }
    @media screen and (max-width: 576px) {
      .about-container .inner-width {
        width: 100%;
        padding: 0 5%; } }
  .about-container section.aflow {
    position: relative;
    overflow: hidden; }
    .about-container section.aflow .main-title {
      font-size: 6rem;
      margin-bottom: 1em;
      position: relative;
      margin-top: 50vh;
      padding-left: 90px;
      padding-right: 90px;
      -webkit-animation-duration: 800ms;
              animation-duration: 800ms; }
      @media screen and (max-width: 576px) {
        .about-container section.aflow .main-title {
          font-size: 4.3rem;
          line-height: 1.35;
          letter-spacing: 0;
          padding: 0 20px;
          word-break: break-word;
          white-space: pre-line; } }
      .about-container section.aflow .main-title > p {
        font-size: 6rem;
        line-height: 1.25;
        display: block;
        text-align: left;
        color: white;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
        word-break: keep-all;
        white-space: nowrap; }
        @media screen and (max-width: 800px) {
          .about-container section.aflow .main-title > p {
            font-size: 4.3rem;
            line-height: 1.35;
            letter-spacing: 0;
            padding: 0;
            word-break: keep-all;
            white-space: pre; } }
    .about-container section.aflow p {
      position: relative;
      font-size: 1.7rem;
      will-change: top, opacity; }
      .about-container section.aflow p.message {
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
        opacity: 0;
        padding-left: 90px;
        padding-right: 90px;
        word-break: keep-all;
        color: #b5b5b5; }
        @media screen and (max-width: 578px) {
          .about-container section.aflow p.message {
            padding: 0 20px; } }
        .about-container section.aflow p.message.ko {
          -webkit-animation-duration: 1100ms;
                  animation-duration: 1100ms;
          line-height: 2.43; }
          @media screen and (max-width: 578px) {
            .about-container section.aflow p.message.ko {
              line-height: 1.8; } }
        .about-container section.aflow p.message.en {
          -webkit-animation-duration: 1200ms;
                  animation-duration: 1200ms;
          padding-bottom: 12.5%;
          line-height: 2.3;
          letter-spacing: -0.015rem; }
          @media screen and (max-width: 578px) {
            .about-container section.aflow p.message.en {
              line-height: 2; } }
  .about-container .white-bg {
    position: relative;
    background-color: #ffffff;
    font-family: "Noto regular", serif;
    overflow: visible; }
    .about-container .white-bg p.title {
      font-family: "Noto bold", serif;
      padding-top: 13%;
      letter-spacing: 0.12rem;
      line-height: 1;
      font-size: 1.4rem; }
      @media screen and (max-width: 576px) {
        .about-container .white-bg p.title {
          margin-top: 14.4%; } }
    .about-container .white-bg p.subtitle {
      font-weight: bold;
      line-height: 1.6;
      font-size: 2.2rem;
      margin-bottom: 2.2rem;
      letter-spacing: 0; }
      @media screen and (max-width: 578px) {
        .about-container .white-bg p.subtitle {
          font-size: 20px;
          margin-bottom: 25px;
          line-height: 1; } }
    .about-container .white-bg .business-wrap {
      height: 420px; }
      .about-container .white-bg .business-wrap > div {
        margin-top: 8.8%;
        display: flex;
        flex-flow: row; }
        @media screen and (max-width: 1000px) {
          .about-container .white-bg .business-wrap > div {
            flex-flow: column;
            height: auto; }
            .about-container .white-bg .business-wrap > div > div {
              margin-top: 14.4%; }
              .about-container .white-bg .business-wrap > div > div:first-of-type {
                margin-top: 0; } }
        .about-container .white-bg .business-wrap > div > div {
          width: 100%;
          flex-grow: 1;
          font-size: 1.4rem;
          line-height: 2.28;
          letter-spacing: 0.03rem;
          z-index: 2; }
          @media screen and (max-width: 578px) {
            .about-container .white-bg .business-wrap > div > div {
              font-size: 12.5px;
              line-height: 2;
              letter-spacing: 0; } }
    .about-container .white-bg .business-wrap2 {
      position: relative;
      margin-top: 0; }
      .about-container .white-bg .business-wrap2 .area-title-list {
        position: relative;
        flex-grow: 1;
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.8rem;
        line-height: 1.6; }
        @media screen and (max-width: 578px) {
          .about-container .white-bg .business-wrap2 .area-title-list {
            line-height: 1.222; } }
        .about-container .white-bg .business-wrap2 .area-title-list > li {
          position: relative;
          margin-top: 60px; }
          .about-container .white-bg .business-wrap2 .area-title-list > li:first-of-type {
            margin-top: 0; }
        @media screen and (min-width: 1000px) {
          .about-container .white-bg .business-wrap2 .area-title-list {
            position: relative;
            width: 50%;
            height: 100%; }
            .about-container .white-bg .business-wrap2 .area-title-list > li {
              margin-top: 0;
              position: absolute; }
              .about-container .white-bg .business-wrap2 .area-title-list > li:first-of-type {
                right: 33.33%;
                bottom: 0;
                -webkit-transform: translateY(100%);
                        transform: translateY(100%); }
              .about-container .white-bg .business-wrap2 .area-title-list > li:nth-of-type(2) {
                top: 0;
                right: 0;
                -webkit-transform: translateX(50%);
                        transform: translateX(50%); }
              .about-container .white-bg .business-wrap2 .area-title-list > li:last-of-type {
                right: 66.6%;
                top: 50%;
                -webkit-transform: translateY(-50%);
                        transform: translateY(-50%); } }
      .about-container .white-bg .business-wrap2 .area-list {
        position: absolute;
        margin: 0;
        width: 50%;
        left: 50%;
        top: 60%;
        padding: 0;
        list-style: none;
        font-size: 1.4rem;
        line-height: 2.14;
        display: flex;
        flex-flow: row;
        -webkit-transform: translate(3rem, -0.5rem);
                transform: translate(3rem, -0.5rem); }
        .about-container .white-bg .business-wrap2 .area-list > li {
          white-space: nowrap; }
          .about-container .white-bg .business-wrap2 .area-list > li:first-of-type {
            width: 130px; }
          .about-container .white-bg .business-wrap2 .area-list > li:nth-of-type(2) {
            width: 170px; }
          .about-container .white-bg .business-wrap2 .area-list > li::nth-of-type(3) {
            width: 150px; }
        @media screen and (max-width: 1000px) {
          .about-container .white-bg .business-wrap2 .area-list {
            flex-flow: column; } }
    .about-container .white-bg .background-shape2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0; }

.about-dark-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  overflow: hidden; }
  .about-dark-background img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: top linear 150ms;
    transition: top linear 150ms; }

.client {
  margin-top: 200px !important; }
  @media screen and (max-width: 576px) {
    .client {
      width: auto;
      margin: 25px; } }
  .client .client-list {
    list-style: none;
    margin: 2% auto 0;
    padding: 0;
    position: relative;
    overflow: hidden; }
    .client .client-list > li {
      margin-top: 2%;
      float: left;
      width: 16.66%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .client .client-list > li > img {
        width: 100%; }
      @media screen and (max-width: 576px) {
        .client .client-list > li {
          width: 33%; } }

.prototype {
  position: absolute !important;
  top: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0.2;
  width: 1600px;
  z-index: 99999;
  pointer-events: none; }

#w0-proto2 {
  top: -2695px; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0) !important;
    transform: translate3d(0, 10%, 0) !important; }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0) !important;
    transform: translate3d(0, -10%, 0) !important; }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0) !important;
    transform: translate3d(0, -10%, 0) !important; }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

.col-1 {
  width: 150px;
  margin: auto; }

.col-2 {
  width: 300px;
  margin: auto; }

.col-3 {
  width: 450px;
  margin: auto; }

.col-4 {
  width: 600px;
  margin: auto; }

.col-5 {
  width: 750px;
  margin: auto; }

.col-6 {
  width: 900px;
  margin: auto; }

h1, h2, h3, h4 {
  font-weight: normal; }

@media screen and (max-width: 576px) {
  body, html {
    font-size: 10px; }
  .appbar {
    opacity: 1 !important; } }

@media screen and (max-width: 576px) and (max-width: 576px) {
  .page .scroll-content {
    overflow-x: hidden; }
  .page .w-overlay-line {
    position: fixed;
    top: 0;
    left: -1px;
    width: 100%;
    height: 100%;
    z-index: 1000;
    opacity: 1; }
    .page .w-overlay-line.animate {
      stroke-dasharray: 3000;
      stroke-dashoffset: 3000;
      -webkit-animation: dash 3s ease-out forwards;
              animation: dash 3s ease-out forwards; }
  .page #work-swiper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: url(/image/w_bg_m.jpg) center top no-repeat;
    background-size: cover; }
    .page #work-swiper .swiper-slide {
      position: relative;
      width: 100%;
      height: 100%; }
      .page #work-swiper .swiper-slide .img-wrap {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; }
        .page #work-swiper .swiper-slide .img-wrap > img {
          position: relative;
          width: 100%; }
  .page #w-overlay-top1 {
    position: fixed;
    top: 0;
    left: 0;
    height: 185px;
    width: 100%;
    z-index: 9;
    background-color: white; }
  .page #w-overlay-top2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    margin-top: 184px;
    height: 50vw;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 0);
            clip-path: polygon(0 0, 0 100%, 100% 0);
    background-color: white;
    z-index: 8; }
  .page #w-overlay-bot {
    position: fixed;
    bottom: 0;
    left: -26vw;
    width: 100vw;
    height: 200vw;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%);
            clip-path: polygon(0 0, 0 100%, 100% 100%);
    z-index: 8; }
    .page #w-overlay-bot.hide {
      display: none; }
  .page .pagination {
    position: fixed;
    margin-top: 50vw;
    top: 178px;
    left: 0;
    width: 32px;
    height: 64px;
    overflow: hidden;
    z-index: 9;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    .page .pagination > svg {
      position: absolute;
      top: 22px;
      left: 0;
      margin-left: -4px;
      width: 40px; }
    .page .pagination > span {
      position: absolute;
      font-size: 13px;
      line-height: 1;
      display: block;
      text-align: center; }
      .page .pagination > span:first-of-type {
        top: 0;
        left: 0;
        width: 100%;
        font-weight: bold; }
      .page .pagination > span:last-of-type {
        bottom: 0;
        left: 0;
        width: 100%; }
  .scroll-down {
    background: url(/image/scroll_down.png) center left no-repeat;
    background-size: 9.5px 6px;
    padding-left: 20px;
    position: fixed;
    left: 3%;
    bottom: 37.5px;
    font-size: 12px;
    line-height: 1;
    z-index: 20;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out; }
  .not-updated {
    position: absolute;
    bottom: 35px;
    left: 3%;
    font-size: 12px;
    color: black;
    z-index: 20;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    opacity: 0; } }

@media screen and (max-width: 576px) {
  .desktop-column {
    margin-left: 3%;
    max-width: 94%;
    padding: 5% 0 0; }
    .desktop-column > img {
      width: 100%; }
  #title-swiper {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: 70vw;
    color: black;
    font-size: 11.2vw;
    text-align: left;
    z-index: 10;
    white-space: pre-line;
    letter-spacing: 0;
    line-height: 12vw; }
    #title-swiper .swiper-slide {
      padding: 20px 0 0 32px;
      white-space: pre; }
      #title-swiper .swiper-slide h3 {
        font-size: 3.5rem;
        line-height: 1.14;
        margin: 0;
        margin-left: -2px;
        opacity: 0; }
      #title-swiper .swiper-slide p {
        margin-left: 0;
        font-size: 13px;
        opacity: 0; }
      #title-swiper .swiper-slide.swiper-slide-active h3,
      #title-swiper .swiper-slide.swiper-slide-active p {
        opacity: 1;
        -webkit-transition: opacity .6s ease-in-out;
        transition: opacity .6s ease-in-out; }
    #title-swiper > div {
      position: absolute; }
  .bg {
    display: block;
    width: 100%; }
  .m-img,
  .l-img,
  .device-img {
    width: 94%;
    margin-left: auto;
    margin-right: auto;
    display: block; }
  .device-img {
    margin-top: -9%; }
  .sec {
    overflow: hidden; }
  .video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    background-color: black; }
    .video-container > iframe {
      position: absolute;
      top: -1%;
      left: -1%;
      width: 102%;
      height: 102%; }
  .video-subtitle {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
    font-size: 1.1rem;
    font-family: 'Noto medium';
    opacity: 0.4;
    color: black;
    width: 94%; }
    .video-subtitle > span.link {
      display: block;
      color: black;
      word-break: break-all; }
  .page {
    margin-top: 70px;
    text-align: center; }
    .page .sec > img {
      max-width: 100%; }
    .page .main-bg {
      height: 100vw;
      background-size: cover !important; }
      .page .main-bg.main-bg2 {
        height: 80vw !important; }
    .page .main-sec {
      width: 100%; }
      .page .main-sec .text-wrap {
        padding: 0 20px 0;
        display: flex;
        flex-flow: column;
        text-align: left; }
        .page .main-sec .text-wrap > div:first-of-type h3 {
          margin: 0;
          font-size: 9.06vw;
          width: 100%;
          line-height: 1.11;
          letter-spacing: 0;
          font-weight: normal;
          white-space: pre-line; }
        .page .main-sec .text-wrap > div:first-of-type ul {
          margin: 35px 0 0;
          padding: 0;
          list-style: none;
          font-size: 4.26vw;
          line-height: 1.5; }
        .page .main-sec .text-wrap > div:last-of-type h4 {
          margin-top: 50px;
          margin-bottom: 35px;
          font-size: 2rem;
          line-height: 1.5;
          font-family: "Acumin Pro", "Noto regular" !important;
          font-weight: normal; }
        .page .main-sec .text-wrap > div:last-of-type > p {
          font-size: 1.5rem;
          line-height: 1.733; }
          .page .main-sec .text-wrap > div:last-of-type > p:last-of-type {
            margin-top: 1em; }
          .page .main-sec .text-wrap > div:last-of-type > p .bold {
            font-weight: 600; }
    .page .credit-sec > p {
      letter-spacing: 0.05rem; }
    .page .swiper-sec {
      position: relative;
      width: 100%;
      background-color: #eeeeee;
      text-align: center; }
    .page .detail-swiper {
      margin-left: auto;
      margin-right: auto;
      width: 94vw;
      height: 62.84vw !important;
      overflow: hidden; }
      .page .detail-swiper .swiper-wrapper .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center; }
        .page .detail-swiper .swiper-wrapper .swiper-slide > img {
          display: block;
          margin: 0 auto;
          max-width: 100%;
          max-height: 100%;
          object-fit: contain; }
      .page .detail-swiper .btn-prev {
        left: 0;
        background: url("/image/works/slide_arr_left.png") no-repeat center;
        background-size: 8px 15px; }
      .page .detail-swiper .btn-next {
        right: 0;
        background: url("/image/works/slide_arr_right.png") no-repeat center;
        background-size: 8px 15px; }
      .page .detail-swiper .btn-prev,
      .page .detail-swiper .btn-next {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        background-color: rgba(255, 255, 255, 0.5);
        -webkit-transition: background-color 0.2s ease-in-out;
        transition: background-color 0.2s ease-in-out;
        cursor: pointer;
        z-index: 9;
        outline: none;
        border: none; }
      .page .detail-swiper .btn-prev:hover,
      .page .detail-swiper .btn-next:hover {
        background-color: rgba(255, 255, 255, 0.8); }
    .page .sec-title-wrap {
      margin-left: 20px;
      margin-right: 20px;
      padding: 0;
      text-align: left; }
      .page .sec-title-wrap > img {
        margin-bottom: 30px;
        width: 100%;
        max-width: 900px;
        display: block; }
      .page .sec-title-wrap h3 {
        margin: 0;
        width: 100%;
        font-size: 3.2rem;
        line-height: 1;
        display: block;
        white-space: pre-line; }
      .page .sec-title-wrap p {
        margin: 1.73em auto 0;
        font-size: 1.5rem;
        line-height: 1.85;
        width: 610px;
        max-width: 100%;
        text-align: left;
        word-break: keep-all; }
    .page h4 {
      font-size: 1.7rem;
      font-family: 'Acumin Pro semibold', 'Noto regular', sans-serif;
      font-weight: bold; }
  .next-proejct-container {
    background-color: white;
    position: relative;
    padding: 0 20px;
    text-align: left;
    z-index: 9; }
    .next-proejct-container .desc {
      padding-bottom: 13px;
      font-size: 0.048vw;
      line-height: 1;
      width: 100%;
      border-bottom: 1px solid #e5e5e5; }
    .next-proejct-container .title {
      margin: 5% 0 0;
      font-size: 3.8rem;
      cursor: pointer;
      margin-left: -2px;
      line-height: 1.07;
      letter-spacing: -0.02rem;
      white-space: nowrap; }
    .next-proejct-container .company {
      margin-left: 1px;
      margin-top: 19px;
      font-size: 1.1rem; }
  .dark-bg {
    background-color: black;
    color: white !important; } }

@media screen and (min-width: 577px) and (min-width: 576px) {
  .page {
    z-index: 10; }
    .page .scroll-container {
      z-index: 9; }
    .page #w-pattern-fix {
      position: fixed;
      margin-top: -150px;
      margin-left: -250px;
      top: 0;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
      width: 3000px;
      z-index: 0; }
    .page #w-pattern-move {
      position: fixed;
      margin-left: -240px;
      top: 0;
      left: 50%;
      width: 3000px;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
      opacity: 0.8;
      z-index: -1; }
    .page .w-overlay-container {
      position: fixed;
      z-index: 5;
      pointer-events: none; }
    .page .w-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none; }
    .page #w-back-overlay {
      z-index: -1; }
    .page #w-overlay-top2 {
      position: fixed;
      top: 0;
      display: none;
      z-index: 9; }
    .page #w-overlay-bot2 {
      z-index: 9; }
    .page .swiper-scrollbar {
      display: none; }
    .page #scroll-swiper {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      .page #scroll-swiper .swiper-slide {
        height: auto;
        min-height: 1000px;
        box-sizing: border-box; }
    .page .work-wrap {
      position: relative;
      margin: 0 auto;
      width: 75%;
      max-width: 1012px;
      min-height: 100vh;
      text-align: left;
      opacity: 0;
      z-index: 0;
      -webkit-transition: opacity .2s ease-in-out;
      transition: opacity .2s ease-in-out; }
      .page .work-wrap #work-title {
        margin-bottom: 25%;
        display: flex;
        align-items: center;
        opacity: 1; }
      .page .work-wrap h3 {
        margin: 0;
        font-size: 4.2rem;
        line-height: 1.071;
        letter-spacing: -0.1rem; }
      .page .work-wrap .work {
        position: relative;
        width: 100%;
        height: 26.25vw;
        max-height: 354px;
        margin-bottom: 8.83%;
        display: flex;
        -webkit-animation-delay: 0.2s;
                animation-delay: 0.2s;
        -webkit-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
        -webkit-transform: translateY(20%);
                transform: translateY(20%);
        -webkit-transition: all 4s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: all 4s cubic-bezier(0.165, 0.84, 0.44, 1); }
        .page .work-wrap .work:last-of-type {
          margin-bottom: 0; }
        .page .work-wrap .work:not(.updated) .img-wrap {
          cursor: default !important; }
          .page .work-wrap .work:not(.updated) .img-wrap:after {
            display: block;
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: white;
            opacity: 0.75; }
        .page .work-wrap .work:not(.updated):after {
          display: block;
          content: 'TO BE UPDATED';
          position: absolute;
          top: 0;
          right: 0;
          width: 2rem;
          height: 100%;
          font-size: 0.9rem;
          color: #999999;
          -webkit-writing-mode: tb-rl;
              -ms-writing-mode: tb-rl;
                  writing-mode: tb-rl;
          -webkit-transform: translateX(100%);
                  transform: translateX(100%);
          font-family: 'Acumin Pro Semibold'; }
        .page .work-wrap .work:not(.updated):nth-of-type(3n-1):after {
          right: 5.55%; }
        .page .work-wrap .work:not(.updated):nth-of-type(3n):after {
          right: 11.1%; }
        .page .work-wrap .work:not(.updated):nth-of-type(3n-2):after {
          right: 0; }
        .page .work-wrap .work > div:first-of-type {
          -webkit-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
          transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
          transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
          transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
          opacity: 0;
          -webkit-transition-delay: 0.2s;
                  transition-delay: 0.2s; }
        .page .work-wrap .work.visible > div:first-of-type {
          opacity: 1;
          -webkit-transform: translateY(0) translateZ(0);
                  transform: translateY(0) translateZ(0); }
        .page .work-wrap .work.hide {
          opacity: 0; }
        .page .work-wrap .work h3 {
          z-index: 40; }
        .page .work-wrap .work:nth-of-type(3n-1) h3 {
          cursor: pointer; }
        .page .work-wrap .work:nth-of-type(3n-1) > .img-wrap {
          right: 5.55%; }
        .page .work-wrap .work:nth-of-type(3n) h3 {
          cursor: pointer; }
        .page .work-wrap .work:nth-of-type(3n) > .img-wrap {
          right: 11.1%; }
        .page .work-wrap .work:nth-of-type(3n-2) h3 {
          cursor: pointer; }
        .page .work-wrap .work.visible {
          -webkit-transform: none;
                  transform: none;
          opacity: 1; }
          .page .work-wrap .work.visible img {
            opacity: 1 !important;
            cursor: pointer; }
        .page .work-wrap .work > div:first-of-type {
          flex-grow: 1; }
          .page .work-wrap .work > div:first-of-type > p {
            margin-left: 2px;
            margin-top: 28px;
            font-size: 1.1rem;
            line-height: 1;
            letter-spacing: 1.6px;
            font-family: "Acumin Pro", "Noto demilight", sans-serif; }
        .page .work-wrap .work > .img-wrap {
          position: absolute;
          top: 0;
          right: 0;
          width: 46.66%;
          height: 100%;
          -webkit-transition: all 0.2s ease-in;
          transition: all 0.2s ease-in;
          background-color: black;
          overflow: hidden;
          cursor: pointer;
          z-index: 0; }
          .page .work-wrap .work > .img-wrap img {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
            object-fit: cover;
            opacity: 1; }
    .page #w-img-overlay {
      position: fixed;
      top: 0;
      left: 50%;
      width: 79.43vw;
      max-width: 1271px;
      background-color: white; }
  @-webkit-keyframes dash {
    to {
      stroke-dashoffset: 0; } }
  @keyframes dash {
    to {
      stroke-dashoffset: 0; } }
    .page .w-overlay-line {
      top: 0;
      z-index: 0;
      pointer-events: none;
      opacity: 0; }
      .page .w-overlay-line.animate {
        stroke-dasharray: 3000;
        stroke-dashoffset: 3000;
        -webkit-animation: dash 3s ease-out forwards;
                animation: dash 3s ease-out forwards; }
    .page .w-overlay-line2, .page .w-overlay-line3 {
      top: 0;
      z-index: 20;
      opacity: 0; }
    .page #w-separator2-box {
      position: fixed;
      bottom: 0;
      z-index: 0; }
    .page .desktop-pagination {
      position: fixed;
      top: 50%;
      left: 3%;
      width: 32px;
      height: 64px;
      overflow: hidden;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      letter-spacing: 0;
      z-index: 10; }
      .page .desktop-pagination > svg {
        position: absolute;
        top: 22px;
        left: 0;
        margin-left: -4px;
        width: 40px; }
      .page .desktop-pagination > span {
        position: absolute;
        font-size: 1.3rem;
        line-height: 1;
        display: block;
        text-align: center; }
        .page .desktop-pagination > span:first-of-type {
          top: 0;
          left: 0;
          width: 100%;
          font-weight: bold; }
        .page .desktop-pagination > span:last-of-type {
          bottom: 0;
          left: 0;
          width: 100%; }
    .page .btn-top {
      position: fixed;
      bottom: 54px;
      left: 3%;
      width: 32px;
      background: transparent;
      border: none;
      border-top: 4px solid black;
      font-size: 1.3rem;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0;
      padding: 16px 0;
      outline: none;
      z-index: 10;
      cursor: pointer;
      -webkit-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;
      opacity: 0; }
      .page .btn-top.visible {
        opacity: 1; }
      .page .btn-top.white {
        color: white;
        border-color: white; }
    .page .pagination {
      position: fixed;
      top: 200px;
      left: 0;
      width: 32px;
      height: 64px;
      overflow: hidden; }
      .page .pagination > svg {
        position: absolute;
        top: 22px;
        left: 0;
        margin-left: -4px;
        width: 40px; }
      .page .pagination > span {
        position: absolute;
        font-size: 1.3rem;
        line-height: 1;
        display: block;
        text-align: center; }
        .page .pagination > span:first-of-type {
          top: 0;
          left: 0;
          width: 100%;
          font-weight: bold; }
        .page .pagination > span:last-of-type {
          bottom: 0;
          left: 0;
          width: 100%; }
  .loading-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-color: white;
    pointer-events: none; } }

@media screen and (min-width: 577px) {
  .desktop-pagination,
  .btn-top {
    opacity: 0;
    -webkit-transition: opacity 1.5s ease-in-out !important;
    transition: opacity 1.5s ease-in-out !important; }
  .appbar .menu-left,
  .appbar .logo,
  .appbar .menu-right {
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out; }
  .appbar .menu-left {
    -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s; }
  .appbar .logo {
    -webkit-transition-delay: 0.55s;
            transition-delay: 0.55s; }
  .appbar .menu-right {
    -webkit-transition-delay: 0.8s;
            transition-delay: 0.8s; }
  .loaded .appbar .menu-left,
  .loaded .appbar .logo,
  .loaded .appbar .menu-right {
    opacity: 1;
    -webkit-transition-duration: 0;
            transition-duration: 0; }
  .loaded .work-wrap {
    -webkit-transition: opacity .6s ease-in-out;
    transition: opacity .6s ease-in-out;
    -webkit-transition-delay: 1s;
            transition-delay: 1s;
    opacity: 1; }
  .loading-bg {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out; }
  .work-scroll {
    overflow-y: scroll; }
  .desktop-column {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    max-width: 1012px; }
    .desktop-column > img {
      width: 45%; }
      .desktop-column > img:last-of-type {
        margin-left: 10%; }
  .page {
    text-align: center;
    padding-top: 100px; }
    .page .main-bg {
      width: 100%;
      height: 600px;
      object-fit: cover;
      background-size: cover !important; } }
    @media screen and (min-width: 577px) and (min-width: 1600px) {
      .page .main-bg {
        height: 37.5vw; } }

@media screen and (min-width: 577px) {
    .page .bg {
      display: block;
      width: 100%; }
    .page .sec {
      overflow: hidden; }
    .page .l-img {
      width: 100%;
      max-width: 1200px !important; }
    .page .m-img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 75%;
      max-width: 1012px; }
    .page .device-img {
      width: 90%;
      max-width: 1074px;
      display: block;
      margin: -9% auto 0; } }
    @media screen and (min-width: 577px) and (min-width: 1350px) {
      .page .device-img {
        margin-top: -112px; } }

@media screen and (min-width: 577px) {
    .page .video-container {
      position: relative;
      width: 75%;
      max-width: 1012px;
      height: 42.1875vw;
      max-height: 569px;
      margin-left: auto;
      margin-right: auto;
      background-color: black;
      overflow: hidden; }
      .page .video-container > iframe {
        position: absolute;
        top: -0.5%;
        left: -0.5%;
        width: 101%;
        height: 101%; }
    .page .video-subtitle {
      margin-top: 15px;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.6;
      font-size: 1.1rem;
      font-family: 'Noto medium';
      opacity: 0.4;
      color: black;
      width: 75%; }
      .page .video-subtitle > span.link {
        margin-left: 20px;
        font-family: 'Noto medium';
        word-break: break-all; }
    .page .main-sec {
      margin: 0 auto; }
      .page .main-sec .text-wrap {
        display: flex;
        flex-flow: row;
        text-align: left;
        width: 75%;
        margin: 0 auto;
        max-width: 1012px; }
        .page .main-sec .text-wrap > div:first-of-type {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
          width: 33%;
          min-width: 300px; }
          .page .main-sec .text-wrap > div:first-of-type.dark-bg {
            border-right: 1px solid rgba(255, 255, 255, 0.15); }
          .page .main-sec .text-wrap > div:first-of-type h3 {
            margin: 0;
            min-width: 350px;
            font-size: 3.4rem;
            line-height: 1.11;
            letter-spacing: 0;
            font-family: "Acumin Pro", "Noto demilight", sans-serif;
            font-weight: normal; }
          .page .main-sec .text-wrap > div:first-of-type ul {
            margin: 23px 0 0;
            padding: 0;
            list-style: none;
            font-size: 1.6rem;
            line-height: 1.5;
            font-family: "Acumin Pro", "Noto demilight", sans-serif; }
        .page .main-sec .text-wrap > div:last-of-type {
          padding-left: 20px;
          width: 67%; }
          .page .main-sec .text-wrap > div:last-of-type h4 {
            margin-top: 0;
            margin-bottom: 1em;
            font-size: 2.8rem;
            line-height: 1.21;
            font-family: "Acumin Pro", "Noto regular", sans-serif;
            font-weight: normal;
            word-break: keep-all; }
          .page .main-sec .text-wrap > div:last-of-type > p {
            font-size: 1.5rem;
            line-height: 1.73;
            font-family: "Acumin Pro", "Noto regular", sans-serif;
            font-weight: normal;
            word-break: keep-all; }
            .page .main-sec .text-wrap > div:last-of-type > p:nth-of-type(2) {
              margin-top: 3em;
              -webkit-transform: translateY(20%);
                      transform: translateY(20%);
              line-height: 1; }
    .page .credit-sec > p {
      letter-spacing: 0.05rem; }
    .page .swiper-sec {
      position: relative;
      width: 100%;
      background-color: #eeeeee;
      text-align: center; }
    .page .detail-swiper {
      margin-left: auto;
      margin-right: auto;
      width: 75%;
      height: 75vw;
      max-width: 1012px;
      max-height: 1012px;
      overflow: hidden; }
      .page .detail-swiper.m-detail-swiper {
        height: 42.15vw !important;
        max-height: 569px !important; }
      .page .detail-swiper.swiper-600 {
        margin-left: auto;
        margin-right: auto;
        width: 75%;
        height: 50vw;
        max-height: 675px; }
      .page .detail-swiper .swiper-wrapper .swiper-slide {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center; }
        .page .detail-swiper .swiper-wrapper .swiper-slide > img {
          display: block;
          margin: auto;
          width: 99.9%;
          height: 100%;
          object-fit: contain; }
      .page .detail-swiper .btn-prev {
        left: 0;
        background: url("/image/works/slide_arr_left.png") no-repeat center; }
      .page .detail-swiper .btn-next {
        right: 0;
        background: url("/image/works/slide_arr_right.png") no-repeat center; }
      .page .detail-swiper .btn-prev,
      .page .detail-swiper .btn-next {
        position: absolute;
        width: 60px;
        height: 60px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        background-color: rgba(255, 255, 255, 0.5);
        -webkit-transition: background-color 0.2s ease-in-out;
        transition: background-color 0.2s ease-in-out;
        cursor: pointer;
        z-index: 9;
        outline: none;
        border: none; }
      .page .detail-swiper .btn-prev:hover,
      .page .detail-swiper .btn-next:hover {
        background-color: rgba(255, 255, 255, 0.8); }
    .page .sec-title-wrap {
      font-family: "Acumin Pro", "Noto regular", serif;
      text-align: center; }
      .page .sec-title-wrap > img {
        margin-left: auto;
        margin-right: auto;
        display: block; }
      .page .sec-title-wrap h3 {
        margin: 0;
        font-size: 3.2rem;
        line-height: 1;
        font-weight: normal; }
      .page .sec-title-wrap p {
        margin-top: 1.73em;
        font-size: 1.5rem;
        line-height: 1.73;
        width: 60%;
        max-width: 810px;
        margin-left: auto;
        margin-right: auto;
        word-break: keep-all; }
    .page h4 {
      font-size: 1.7rem;
      font-family: 'Acumin Pro Semibold', sans-serif;
      font-weight: bold; }
  .next-proejct-container {
    position: absolute;
    top: 25%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 75%;
    max-width: 1012px;
    text-align: left;
    z-index: 9; }
    .next-proejct-container .desc {
      display: block;
      font-size: 1.8rem;
      line-height: 2.2;
      width: 50%;
      border-bottom: 1px solid #e5e5e5; }
    .next-proejct-container .title {
      margin: 5% 0 0;
      font-size: 4.2rem;
      cursor: pointer;
      margin-left: -2px; }
    .next-proejct-container .company {
      margin-left: 2px;
      margin-top: 1.58%;
      font-size: 1.1rem; }
  .dark-bg {
    background-color: black;
    color: white !important; } }

@media (min-width: 578px) and (max-width: 1200px) {
  body, html {
    font-size: 10px; } }

@media (min-width: 1201px) and (max-width: 1350px) {
  body, html {
    font-size: 0.8333vw; } }

@media screen and (min-width: 1351px) {
  body, html {
    font-size: 11.2px; } }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

#app {
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: 100vh; }

.page {
  position: relative;
  flex-grow: 1;
  overflow: hidden; }

div,
section,
p {
  margin: 0;
  box-sizing: border-box; }

.vertical-center-row {
  display: flex;
  align-items: center;
  justify-content: center; }

.background-shape {
  width: 100%;
  height: 100%;
  position: relative; }

.scroll-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

@media screen and (max-width: 577px) {
  .mobile-only {
    display: block; }
  .pc-only {
    display: none; } }

@media screen and (min-width: 578px) {
  .mobile-only {
    display: none; }
  .pc-only {
    display: block; } }

@media screen and (max-width: 577px) {
  .py-5 {
    padding-top: 25px;
    padding-bottom: 25px; }
  .py-10 {
    padding-top: 50px;
    padding-bottom: 50px; }
  .my-5 {
    margin-top: 25px;
    margin-bottom: 25px; }
  .pt-10 {
    padding-top: 50px; }
  .pb-10 {
    padding-bottom: 50px; }
  .pb-12 {
    padding-bottom: 60px; }
  .mt-5 {
    margin-top: 25px; }
  .mt-10 {
    margin-top: 50px; }
  .mb-5 {
    margin-bottom: 25px; }
  .mb-10 {
    margin-bottom: 50px; }
  .my-10 {
    margin-top: 50px;
    margin-bottom: 50px; }
  .mt-12 {
    margin-top: 60px; } }

@media (min-width: 578px) and (max-width: 1349px) {
  .py-5 {
    padding-top: 4.166%;
    padding-bottom: 4.166%; }
  .py-10 {
    padding-top: 8.33%;
    padding-bottom: 8.33%; }
  .my-5 {
    margin-top: 4.166%;
    margin-bottom: 4.166%; }
  .pt-10 {
    padding-top: 8.33%; }
  .pb-10 {
    padding-bottom: 8.33%; }
  .pb-12 {
    padding-bottom: 10%; }
  .mt-5 {
    margin-top: 4.166%; }
  .mt-10 {
    margin-top: 8.33%; }
  .mb-5 {
    margin-bottom: 4.166%; }
  .mb-10 {
    margin-bottom: 8.33%; }
  .my-10 {
    margin-top: 8.33%;
    margin-bottom: 8.33%; }
  .mt-12 {
    margin-top: 10%; } }

@media screen and (min-width: 1350px) {
  .py-5 {
    padding-top: 56px;
    padding-bottom: 56px; }
  .py-10 {
    padding-top: 112px;
    padding-bottom: 112px; }
  .my-5 {
    margin-top: 56px;
    margin-bottom: 56px; }
  .pt-10 {
    padding-top: 112px; }
  .pb-10 {
    padding-bottom: 112px; }
  .pb-12 {
    padding-bottom: 120px; }
  .mt-5 {
    margin-top: 56px; }
  .mt-10 {
    margin-top: 112px; }
  .mb-5 {
    margin-bottom: 56px; }
  .mb-10 {
    margin-bottom: 112px; }
  .my-10 {
    margin-top: 112px;
    margin-bottom: 112px; }
  .mt-12 {
    margin-top: 135px; } }

