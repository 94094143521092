@import "resources/font/Alogo/style.css";
@import "resources/font/AcuminPro/style.css";
@import "resources/font/NotoSans-subset-master/style.css";

/*
Font List:
    * Acumin Pro
    * Acumin Pro italic
    * Acumin Pro bold
    * Acumin Pro bold italic

    * Alogo

    * Noto regular
    * Noto medium
    * Noto thin
    * Noto light
    * Noto demilight
    * Noto bold
    * Noto black
 */

* {
  font-family: "Acumin Pro", "Noto demilight", serif;
}

.alogo {
  font-family: "Alogo", "Acumin Pro", "Noto demilight", serif !important;
}

.font-semibold {
  font-family: 'Acumin Pro semibold', 'Noto regular', sans-serif !important;
  font-weight: bold !important;
}

.font-bold {
  font-family: 'Acumin Pro Bold';
}
