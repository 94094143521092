
@font-face {
    font-family: 'Noto regular';
    font-style: normal;
    font-weight: normal;
    src: url('./NotoSans-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Noto medium';
    font-style: normal;
    font-weight: normal;
    src: url('./NotoSans-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Noto thin';
    font-style: normal;
    font-weight: normal;
    src: url('./NotoSans-Thin.woff') format('woff');
}

@font-face {
    font-family: 'Noto light';
    font-style: normal;
    font-weight: normal;
    src: url('./NotoSans-Light.woff') format('woff');
}

@font-face {
    font-family: 'Noto demilight';
    font-style: normal;
    font-weight: normal;
    src: url('./NotoSans-DemiLight.woff') format('woff');
}

@font-face {
    font-family: 'Noto bold';
    font-style: normal;
    font-weight: normal;
    src: url('./NotoSans-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Noto black';
    font-style: normal;
    font-weight: normal;
    src: url('./NotoSans-Black.woff') format('woff');
}