// TODO: 반응형 적용

$col-width: 150px;

@media screen and (max-width: 1400px)  {
  $col-width: 12.5vw;
}

.col-1 {
  width: $col-width;
  margin: auto;
}

.col-2 {
  width: $col-width * 2;
  margin: auto;
}

.col-3 {
  width: $col-width * 3;
  margin: auto;
}

.col-4 {
  width: $col-width * 4;
  margin: auto;
}

.col-5 {
  width: $col-width * 5;
  margin: auto;
}

.col-6 {
  width: $col-width * 6;
  margin: auto;
}