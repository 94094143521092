.appbar {
  $height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $height;
  text-align: center;
  letter-spacing: 0.5px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  &.bg-white {
    background-color: white;
  }

  * {
    font-family: "Alogo", "Acumin Pro", "Noto demilight", serif;
  }
  -webkit-transition: color 0.5s ease,
    top cubic-bezier(0.25, 0.46, 0.45, 0.94) 350ms;
  -moz-transition: color 0.5s ease,
    top cubic-bezier(0.25, 0.46, 0.45, 0.94) 350ms;
  -ms-transition: color 0.5s ease,
    top cubic-bezier(0.25, 0.46, 0.45, 0.94) 350ms;
  -o-transition: color 0.5s ease, top cubic-bezier(0.25, 0.46, 0.45, 0.94) 350ms;
  transition: color 0.5s ease, top cubic-bezier(0.25, 0.46, 0.45, 0.94) 350ms;

  &.hide {
    top: -$height;
  }

  .logo {
    display: inline-block;
    width: 24px;
    height: 24px;
  }

  &.white {
    .logo {
      fill: white;
    }

    .menu-mobile {
      fill: white;
    }
  }

  * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .menu-left,
  .menu-right {
    display: flex;
    position: absolute;
    top: 0;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    height: 100%;
    outline: none;
  }
  .menu-left {
    left: 3%;
    text-align: left;
    opacity: 0.7;

    #appbar-swiper {
      width: 100px;
      height: 30px;
      overflow: hidden;

      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;
      }
    }
  }
  .menu-right {
    right: 3%;
    text-align: right;

    > .link {
      display: inline-block;
      margin-left: 30px;
      width: 60px;
      text-align: center;
      height: 30px;
      line-height: 30px;
    }
  }

  &.white * {
    color: #ffffff;
  }

  &.black * {
    color: #000000;
  }

  a {
    text-decoration: none;
    font-family: 'Alogo', 'Acumin Pro';

    @media screen and (min-width: 578px) {
      &.active, &:hover {
        font-family: 'Alogo', 'Acumin Pro Semibold';
        font-weight: bold;
      }
    }
  }
}

@-webkit-keyframes top-nav-show {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes top-nav-hide {
  from {
    -webkit-transform: none;
    transform: none;
  }
  to {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.menu-mobile {
  display: none;
}

.mobile-menu-page {
  position: fixed;
  top: 0;
  
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: -1;
  transition: all .2s ease-in-out;

  &.hide {
    left: 100%;
    opacity: 0;

    >.menus {
      opacity: 0;
    }
  }

  &.show {
    left: 0;
    opacity: 1;
    z-index: 2000;

    >.menus {
      opacity: 1;
    }
  }
  

  >.close-btn {
    position: absolute;
    margin: 0;
    padding: 15px;
    top: 0;  
    right: 0;
    width: 40px;
    line-height: 40px;
    font-size: 4.44vw;
    text-align: right;
    color: white;
    border: none;
    outline: none;
    background: transparent;
  }

  >.menus {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
    text-align: center;
    color: white;
    font-size: 9.77vw;
    transition: all .3s ease-in-out;
    transition-delay: .3s;
    // display: flex;
    // flex-flow: column;
    // align-items: center;
    // justify-content: center;

    * {
      color: white;
      line-height: 1;
    }

    > img {
      display: block;
      margin: 30px auto;
      width: 28.5px;
    }

    > p {
      line-height: 85px;
      display: block;
    }

    > a {
      color: white;
      text-decoration: none;
      font-size: 9.77vw;
      line-height: 1;
      color: white;
      font-weight: normal;

      &:hover, &:active {
        font-weight: normal;
      }

      &:last-of-type {

        _::-webkit-full-page-media, _:future, :root .safari_only {
          -webkit-transform: translateX(-10%);
        }        
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .appbar {
    height: 70px;
    
    &.black {
      background-color: white;
    }

    &.white {
      background-color: black;
    }

    .menu-left {
      left: 20px;
    }

    .menu-right {
      right: 20px;
      display: none;
    }

    .menu-mobile {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .appbar .logo {
      transition: all 1s ease-in-out;
    }
  }
}