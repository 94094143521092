/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'Acumin Pro';
font-style: normal;
font-weight: normal;
src: local('Acumin Pro'), url('./Acumin-RPro.woff') format('woff');
}


@font-face {
font-family: 'Acumin Pro italic';
font-style: normal;
font-weight: normal;
src: local('Acumin Pro Italic'), url('./Acumin-ItPro.woff') format('woff');
}


@font-face {
font-family: 'Acumin Pro bold';
font-style: normal;
font-weight: normal;
src: local('Acumin Pro Bold'), url('./Acumin-BdPro.woff') format('woff');
}


@font-face {
font-family: 'Acumin Pro bold italic';
font-style: normal;
font-weight: normal;
src: local('Acumin Pro Bold Italic'), url('./Acumin-BdItPro.woff') format('woff');
}

@font-face {
  font-family: 'Acumin Pro Semibold';
  font-style: normal;
  font-weight: normal;
  src: url('./Acumin-Semibold.woff') format('woff');
  }
  