.bg {
  display: block;
  width: 100%;
}

.m-img,
.l-img,
.device-img {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.device-img {
  margin-top: -9%;
}

.sec {
  overflow: hidden;
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  background-color: black;


  > iframe {
    position: absolute;
    top: -1%;
    left: -1%;
    width: 102%;
    height: 102%;
  }
}

.video-subtitle {
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
  font-size: 1.1rem;
  font-family: 'Noto medium';
  opacity: 0.4;
  color: black;
  width: 94%;

  > span.link {
    display: block;
    color: black;
    word-break: break-all;
  }
}

.page {
  margin-top: 70px;
  text-align: center;

  .sec > img {
    max-width: 100%;
  }

  .main-bg {
    height: 100vw;
    background-size: cover !important;

    &.main-bg2 {
      height: 80vw !important;
    }
  }

  .main-sec {
    width: 100%;

    .text-wrap {
      padding: 0 20px 0;
      display: flex;
      flex-flow: column;
      text-align: left;

      > div:first-of-type {
        h3 {
          margin: 0;
          font-size: 9.06vw;
          width: 100%;
          line-height: 1.11;
          letter-spacing: 0;
          font-weight: normal;
          white-space: pre-line;
        }

        ul {
          margin: 35px 0 0;
          padding: 0;
          list-style: none;
          font-size: 4.26vw;
          line-height: 1.5;
        }
      }

      > div:last-of-type {
        h4 {
          margin-top: 50px;
          margin-bottom: 35px;
          font-size: 2rem;
          line-height: 1.5;
          font-family: "Acumin Pro", "Noto regular" !important;
          font-weight: normal;
        }

        > p {
          font-size: 1.5rem;
          line-height: 1.733;

          &:last-of-type {
            margin-top :1em;
          }

          .bold {
            font-weight: 600;
          }
        }
      }
    }
  }

  .credit-sec {
    > p {
      letter-spacing: 0.05rem;
    }
  }

  .swiper-sec {
    position: relative;
    width: 100%;
    background-color: #eeeeee;
    text-align: center;
  }

  .detail-swiper {
    margin-left: auto;
    margin-right: auto;
    width: 94vw;
    // height: 63.75vw !important;
    height: 62.84vw !important;
    overflow: hidden;

    .swiper-wrapper {
      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
          display: block;
          margin: 0 auto;
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }

    .btn-prev {
      left: 0;
      background: url("/image/works/slide_arr_left.png") no-repeat center;
      background-size: 8px 15px;
    }

    .btn-next {
      right: 0;
      background: url("/image/works/slide_arr_right.png") no-repeat center;
      background-size: 8px 15px;
    }

    .btn-prev,
    .btn-next {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(255, 255, 255, 0.5);
      transition: background-color 0.2s ease-in-out;
      cursor: pointer;
      z-index: 9;
      outline: none;
      border: none;
    }

    .btn-prev:hover,
    .btn-next:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  .sec-title-wrap {
    margin-left: 20px;
    margin-right: 20px;
    padding: 0;
    text-align: left;

    > img {
      margin-bottom: 30px;
      width: 100%;
      max-width: 900px;
      display: block;
    }

    h3 {
      margin: 0;
      width: 100%;
      font-size: 3.2rem;
      line-height: 1;
      display: block;
      white-space: pre-line;
    }

    p {
      margin: 1.73em auto 0;
      font-size: 1.5rem;
      line-height: 1.85;
      width: 610px;
      max-width: 100%;
      text-align: left;
      word-break: keep-all;
    }
  }

  h4 {
    font-size: 1.7rem;
    font-family: 'Acumin Pro semibold', 'Noto regular', sans-serif;
    font-weight: bold;
  }
}

.next-proejct-container {
  background-color: white;
  position: relative;
  padding: 0 20px;
  text-align: left;
  z-index: 9;

  .desc {
    padding-bottom: 13px;
    font-size: 0.048vw;
    line-height: 1;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
  }

  .title {
    margin:5% 0 0;
    font-size: 3.8rem;
    cursor: pointer;
    margin-left: -2px;
    line-height: 1.07;
    letter-spacing: -0.02rem;
    white-space: nowrap;
  }

  .company {
    margin-left: 1px;
    margin-top: 19px;
    font-size: 1.1rem;
  }
}

.dark-bg {
  background-color: black;
  color: white !important;
}