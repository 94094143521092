.page {
  text-align: center;
  padding-top: 100px;

  .main-bg {
    width: 100%;
    height: 600px;
    object-fit: cover;
    background-size: cover !important;

    @media screen and (min-width: 1600px) {
      // height: 50vw;
      height: 37.5vw;
    }
  }

  .bg {
    display: block;
    width: 100%;
  }

  .sec {
    overflow: hidden;
  }

  .l-img {
    width: 100%;
    max-width: 1200px !important;
  }

  .m-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    max-width: 1012px;
  }

  .device-img {
    width: 90%;
    max-width: 1074px;
    display: block;
    margin: -9% auto 0;

    @media screen and (min-width: 1350px) {
      margin-top: -112px;
    }
  }

  .video-container {
    position: relative;
    width: 75%;
    max-width: 1012px;
    height: 42.1875vw;
    max-height: 569px;
    margin-left: auto;
    margin-right: auto;
    background-color: black;
    overflow: hidden;

    > iframe {
      position: absolute;
      top: -0.5%;
      left: -0.5%;
      width: 101%;
      height: 101%;
    }
  }

  .video-subtitle {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
    font-size: 1.1rem;
    font-family: 'Noto medium';
    opacity: 0.4;
    color: black;
    width: 75%;

  
    > span.link {
      margin-left: 20px;
      font-family: 'Noto medium';
      word-break: break-all;
    }
  }

  .main-sec {
    margin: 0 auto;

    .text-wrap {

      display: flex;
      flex-flow: row;
      text-align: left;
      width: 75%;
      margin: 0 auto;
      max-width: 1012px;

      > div:first-of-type {
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        width: 33%;
        min-width: 300px;

        &.dark-bg {
          border-right: 1px solid rgba(255, 255, 255, 0.15);
        }

        h3 {
          margin: 0;
          min-width: 350px;
          font-size: 3.4rem;
          line-height: 1.11;
          letter-spacing: 0;
          font-family: "Acumin Pro", "Noto demilight", sans-serif;
          font-weight: normal;
        }

        ul {
          margin: 23px 0 0;
          padding: 0;
          list-style: none;
          font-size: 1.6rem;
          line-height: 1.5;
          font-family: "Acumin Pro", "Noto demilight", sans-serif;
        }
      }

      > div:last-of-type {
        padding-left: 20px;
        width: 67%;

        h4 {
          margin-top: 0;
          margin-bottom: 1em;
          font-size: 2.8rem;
          line-height: 1.21;
          font-family: "Acumin Pro", "Noto regular", sans-serif;
          font-weight: normal;
          word-break: keep-all;
        }

        > p {
          font-size: 1.5rem;
          line-height: 1.73;
          font-family: "Acumin Pro", "Noto regular", sans-serif;
          font-weight: normal;
          word-break: keep-all;

          &:nth-of-type(2) {
            margin-top :3em;
            transform: translateY(20%);
            line-height: 1;
          }
        }
      }
    }
  }

  .credit-sec {
    > p {
      letter-spacing: 0.05rem;
    }
  }

  .swiper-sec {
    position: relative;
    width: 100%;
    background-color: #eeeeee;
    text-align: center;
  }

  .detail-swiper {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    height: 75vw;
    max-width: 1012px;
    max-height: 1012px;
    overflow: hidden;

    &.m-detail-swiper {
      height: 42.15vw !important;
      max-height: 569px !important;
    }

    &.swiper-600 {
      margin-left: auto;
      margin-right: auto;
      width: 75%;
      height: 50vw;
      max-height: 675px;
    }
  

    .swiper-wrapper {
      .swiper-slide {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
          display: block;
          margin: auto;
          width: 99.9%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .btn-prev {
      left: 0;
      background: url("/image/works/slide_arr_left.png") no-repeat center;
    }

    .btn-next {
      right: 0;
      background: url("/image/works/slide_arr_right.png") no-repeat center;
    }

    .btn-prev,
    .btn-next {
      position: absolute;
      width: 60px;
      height: 60px;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(255, 255, 255, 0.5);
      transition: background-color 0.2s ease-in-out;
      cursor: pointer;
      z-index: 9;
      outline: none;
      border: none;
    }

    .btn-prev:hover,
    .btn-next:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  .sec-title-wrap {
    font-family: "Acumin Pro", "Noto regular", serif;
    text-align: center;

    > img {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    h3 {
      margin: 0;
      font-size: 3.2rem;
      line-height: 1;
      font-weight: normal;
    }

    p {
      margin-top: 1.73em;
      font-size: 1.5rem;
      line-height: 1.73;
      width: 60%;
      max-width: 810px;
      margin-left: auto;
      margin-right: auto;
      word-break: keep-all;
    }
  }

  h4 {
    font-size: 1.7rem;
    font-family: 'Acumin Pro Semibold', sans-serif;
    font-weight: bold;
  }
}
.next-proejct-container {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  max-width: 1012px;
  text-align: left;
  z-index: 9;

  .desc {
    display: block;
    font-size: 1.8rem;
    line-height: 2.2;
    width: 50%;
    border-bottom: 1px solid #e5e5e5;
  }

  .title {
    margin:5% 0 0;
    font-size: 4.2rem;
    cursor: pointer;
    margin-left: -2px;
  }

  .company {
    margin-left: 2px;
    margin-top: 1.58%;
    font-size: 1.1rem;
  }
}

.dark-bg {
  background-color: black;
  color: white !important;
}