@media screen and (min-width: 576px) {
  .page {
    z-index: 10;

    .scroll-container {
      z-index: 9;
    }

    #w-pattern-fix {
      position: fixed;
      margin-top: -150px;
      margin-left: -250px;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 3000px;
      z-index: 0;
    }

    #w-pattern-move {
      position: fixed;
      margin-left: -240px;
      top: 0;
      left: 50%;
      width: 3000px;
      transform: translate(-50%, 0);
      opacity: 0.8;
      z-index: -1;
    }

    .w-overlay-container {
      position: fixed;
      z-index: 5;
      pointer-events:none;
    }

    .w-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events:none;
    }

    #w-back-overlay {
      z-index: -1;
    }

    #w-overlay-top2 {
      position: fixed;
      top: 0;
      display: none;
      z-index: 9;
    }

    #w-overlay-bot2 {
      z-index: 9;
    }

    .swiper-scrollbar {
      display: none;
    }

    #scroll-swiper {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .swiper-slide {
        height: auto;
        min-height: 1000px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
    }

    .work-wrap {
      position: relative;
      margin: 0 auto;
      width: 75%;
      max-width: 1012px;
      min-height: 100vh;
      text-align: left;
      opacity: 0;
      z-index: 0;
      transition: opacity .2s ease-in-out;

      #work-title {
        margin-bottom: 25%;
        display: flex;
        align-items: center;
        opacity: 1;
      }

      h3 {
        margin: 0;
        font-size: 4.2rem;
        line-height: 1.071;
        letter-spacing: -0.1rem;
      }

      .work {
        position: relative;
        width: 100%;
        height: 26.25vw;
        max-height: 354px;
        margin-bottom: 8.83%;
        display: flex;
        animation-delay: 0.2s;
        transition: opacity 0.3s ease-in-out;

        &:last-of-type {
          margin-bottom: 0;
        }

        &:not(.updated)  {
          .img-wrap {
            cursor: default !important;
  
            &:after {
              display: block;
              content: ' ';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: white;
              opacity: 0.75;
            }
          }
          
          &:after {
            display: block;
            content: 'TO BE UPDATED';
            position: absolute;
            top: 0;
            right: 0;
            width: 2rem;
            height: 100%;
            font-size: 0.9rem;
            color: #999999;
            writing-mode: tb-rl;
            transform: translateX(100%);
            font-family: 'Acumin Pro Semibold'
          }

          &:nth-of-type(3n-1) {

            &:after {
              right: 5.55%;
            }
          }
  
          &:nth-of-type(3n) {
  
            &:after {
              right: 11.1%;
            }
          }
  
          &:nth-of-type(3n-2) {
            &:after {
              right: 0;
            }
          }
        }

        > div:first-of-type {
          transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1),
            opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
          opacity: 0;
          transition-delay: 0.2s;
        }

        &.visible > div:first-of-type {
          opacity: 1;
          transform: translateY(0) translateZ(0);
        }

        &.hide {
          opacity: 0;
        }

        h3 {
          z-index: 40;
        }

        &:nth-of-type(3n-1) {
          h3 {
            cursor: pointer;
          }

          > .img-wrap {
            right: 5.55%;
          }
        }

        &:nth-of-type(3n) {
          h3 {
            cursor: pointer;
          }

          > .img-wrap {
            right: 11.1%;
          }
        }

        &:nth-of-type(3n-2) {
          h3 {
            cursor: pointer;
          }
        }

        transform: translateY(20%);
        -webkit-transition: all 4s cubic-bezier(0.165, 0.840, 0.440, 1.000); 
        -moz-transition: all 4s cubic-bezier(0.165, 0.840, 0.440, 1.000); 
       -o-transition: all 4s cubic-bezier(0.165, 0.840, 0.440, 1.000); 
        transition: all 4s cubic-bezier(0.165, 0.840, 0.440, 1.000);

        &.visible {
          transform: none;
          opacity: 1;

          img {
            opacity: 1 !important;
            cursor: pointer;
          }
        }

        > div:first-of-type {
          flex-grow: 1;

          > p {
            margin-left: 2px;
            margin-top: 28px;
            font-size: 1.1rem;
            line-height: 1;
            letter-spacing: 1.6px;
            font-family: "Acumin Pro", "Noto demilight", sans-serif;
          }
        }

        > .img-wrap {
          position: absolute;
          top: 0;
          right: 0;
          width: 46.66%;
          height: 100%;
          transition: all 0.2s ease-in;
          background-color: black;
          overflow: hidden;
          cursor: pointer;
          z-index: 0;
          
          img {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
            object-fit: cover;
            opacity: 1;
            
            // transition: opacity 1s ease-in-out;
            // transition-delay: 0.1s;
          }
        }
      }
    }

    #w-img-overlay {
      position: fixed;
      top: 0;
      left: 50%;
      width: 79.43vw;
      max-width: 1271px;
      background-color: white;
    }

    @keyframes dash {
      to {
        stroke-dashoffset: 0;
      }
    }

    .w-overlay-line {
      top:0;
      z-index: 0;
      pointer-events:none;
      opacity: 0;

      &.animate {
        stroke-dasharray: 3000;
        stroke-dashoffset: 3000;
        animation: dash 3s ease-out forwards;
      }
    }

    .w-overlay-line2, .w-overlay-line3 {
      top: 0;
      z-index: 20;
      opacity: 0;
    }

    #w-separator2-box {
      position: fixed;
      bottom: 0;
      z-index: 0;
    }

    .desktop-pagination {
      position: fixed;
      top: 50%;
      left: 3%;
      width: 32px;
      height: 64px;
      overflow: hidden;
      transform: translateY(-50%);
      letter-spacing: 0;
      z-index: 10;

      > svg {
        position: absolute;
        top: 22px;
        left: 0;
        margin-left: -4px;
        width: 40px;
      }

      > span {
        position: absolute;
        font-size: 1.3rem;
        line-height: 1;
        display: block;
        text-align: center;

        &:first-of-type {
          top: 0;
          left: 0;
          width: 100%;
          font-weight: bold;
        }

        &:last-of-type {
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }
    }

    .btn-top {
      position: fixed;
      bottom: 54px;
      left: 3%;
      width: 32px;
      background: transparent;
      border: none;
      border-top: 4px solid black;
      font-size: 1.3rem;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0;
      padding: 16px 0;
      outline: none;
      z-index: 10;
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;
      opacity: 0;

      &.visible {
        opacity: 1;
      }

      &.white {
        color: white;
        border-color: white;
      }
    }

    // @media screen and (max-width: 1200px) {
    //   .desktop-pagination,
    //   .btn-top {
    //     left: 30px;
    //   }
    // }

    .pagination {
      position: fixed;
      top: 200px;
      left: 0;
      width: 32px;
      height: 64px;
      overflow: hidden;

      > svg {
        position: absolute;
        top: 22px;
        left: 0;
        margin-left: -4px;
        width: 40px;
      }

      > span {
        position: absolute;
        font-size: 1.3rem;
        line-height: 1;
        display: block;
        text-align: center;

        &:first-of-type {
          top: 0;
          left: 0;
          width: 100%;
          font-weight: bold;
        }

        &:last-of-type {
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }
    }
  }

  .loading-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-color: white;
    pointer-events:none;
  }
}

.desktop-pagination,
.btn-top {
  opacity: 0;
  transition: opacity 1.5s ease-in-out !important;
}

.appbar {
  .menu-left,
  .logo,
  .menu-right {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .menu-left {
    transition-delay: 0.3s;
  }

  .logo {
    transition-delay: 0.55s;
  }

  .menu-right {
    transition-delay: 0.8s;
  }
}

.loaded .appbar {
  .menu-left,
  .logo,
  .menu-right  {
    opacity: 1;
    transition-duration: 0;
  }
}
.loaded .work-wrap {
  transition: opacity .6s ease-in-out;
  transition-delay: 1s;
  opacity: 1; 
}

.loading-bg {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.work-scroll {
  overflow-y: scroll;
}

.desktop-column {
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  max-width: 1012px;

  > img {
    width: 45%;

    &:last-of-type {
      margin-left: 10%;
    }
  }
}

// #footer {
//   display: none;
// }

// .loaded {

//   #footer {
//     display: block;
//   }
// }
