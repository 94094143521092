@import "styles/font.scss";
@import "styles/appbar.scss";
@import "styles/footer.scss";
@import "styles/about.scss";
@import "styles/animate.scss";
@import "styles/responsive.scss";

h1,h2,h3,h4 {
  font-weight: normal;
}

@media screen and (max-width: 576px) {
  body, html {
    font-size: 10px;
  }

  .appbar {
    opacity: 1 !important;
  }

  @import "styles/works.mobile.scss";
  @import "styles/workDetail.mobile.scss";
}

@media screen and (min-width: 577px) {
  @import "styles/works.desktop.scss";
  @import "styles/workDetail.scss";
}

@media (min-width: 578px)  and (max-width: 1200px) {
  body, html {
    font-size: 10px;
  }
}

@media (min-width: 1201px)  and (max-width: 1350px) {
  body, html {
    font-size: 0.8333vw;
  }
}

@media screen and (min-width: 1351px) {
  body, html {
    font-size: 11.2px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#app {
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: 100vh;
}

.page {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
}

div,
section,
p {
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.vertical-center-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-shape {
  width: 100%;
  height: 100%;
  position: relative;
}

.scroll-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 577px) {
  .mobile-only {
    display: block;
  }

  .pc-only {
    display: none;
  }
}
@media screen and (min-width: 578px) {
  .mobile-only {
    display: none;
  }

  .pc-only {
    display: block;
  }
}

@media screen and (max-width: 577px) {
  .py-5 {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  
  .py-10 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .my-5 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
  .pt-10 {
    padding-top: 50px;
  }
  
  .pb-10 {
    padding-bottom: 50px;
  }

  .pb-12 {
    padding-bottom: 60px;
  }
  
  .mt-5 {
    margin-top: 25px;
  }
  
  .mt-10 {
    margin-top: 50px;
  }
  
  .mb-5 {
    margin-bottom: 25px;
  }
  
  .mb-10 {
    margin-bottom: 50px;
  }
  
  .my-10 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .mt-12 {
    margin-top: 60px;
  }
}


@media (min-width: 578px) and (max-width: 1349px) {
  .py-5 {
    padding-top: 4.166%;
    padding-bottom: 4.166%;
  }
  
  .py-10 {
    padding-top: 8.33%;
    padding-bottom: 8.33%;
  }
  
  .my-5 {
    margin-top: 4.166%;
    margin-bottom: 4.166%;
  }
  
  .pt-10 {
    padding-top: 8.33%;
  }
  
  .pb-10 {
    padding-bottom: 8.33%;
  }

  .pb-12 {
    padding-bottom: 10%;
  }
  
  .mt-5 {
    margin-top: 4.166%;
  }
  
  .mt-10 {
    margin-top: 8.33%;
  }
  
  .mb-5 {
    margin-bottom: 4.166%;
  }
  
  .mb-10 {
    margin-bottom: 8.33%;
  }
  
  .my-10 {
    margin-top: 8.33%;
    margin-bottom: 8.33%;
  }
  
  .mt-12 {
    margin-top: 10%;
  }
}

@media screen and (min-width: 1350px) {
  .py-5 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  
  .py-10 {
    padding-top: 112px;
    padding-bottom: 112px;
  }
  
  .my-5 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  
  .pt-10 {
    padding-top: 112px;
  }
  
  .pb-10 {
    padding-bottom: 112px;
  }

  .pb-12 {
    padding-bottom: 120px;
  }
  
  .mt-5 {
    margin-top: 56px;
  }
  
  .mt-10 {
    margin-top: 112px;
  }
  
  .mb-5 {
    margin-bottom: 56px;
  }
  
  .mb-10 {
    margin-bottom: 112px;
  }
  
  .my-10 {
    margin-top: 112px;
    margin-bottom: 112px;
  }
  
  .mt-12 {
    margin-top: 135px;
  }
}